.rounds {
   margin-bottom: 8.3vw;
   position: relative;
   z-index: 10;

   .fon {
      @include size(56.5vw, 58.96vw);
      position: absolute;
      top: -4vw;
      right: -9vw;
      z-index: 1;
   }

   .rounds_content {

      width: 100%;

      h1 {
         @extend %text4050;
         margin-bottom: 5.55vw;
      }

      .rounds_content_text {
         @include flex(space-between, center);
         margin-bottom: 6.06vw;

         h2 {
            @include text($c2, 500, 2.08vw);
         }
      }

      .rounds_content_text.two {
         margin: 2.78vw 0;
      }

      .rounds_content_round {
         position: relative;

         img.web {
            @include size(100%, 5.55vw);
         }

         img.mobile {
            display: none;
         }

         ul {
            list-style: none;
         }

         ul.mobile {
            display: none
         }

         ul.top {
            @include flex(flex-start, center);
            position: absolute;
            top: -2.36vw;

            li {
               @include text($c2, 500, 1.1vw);
            }

            li:nth-child(1) {
               margin: 0 22.08vw 0 12.99vw;
            }

            li:nth-child(2) {
               margin-right: 22.08vw;
            }

         }

         ul.bot {
            @include flex(space-around, center);
            margin: 1.04vw 0 4.17vw 0;

            li {
               @extend %text400;
               text-align: center;

               span {
                  display: inline-block;
                  margin-bottom: .5vw
               }
            }
         }
      }

      .rounds_content_buttons {
         .rounds_content_buttons_participate {
            @include bcsize(100%, 3.82vw, $c2);
            border-radius: 2.43vw;
            @include flex(center, center);
            @extend %textdark;
            cursor: pointer;
            transition: all .2s ease-in;
         }

         .rounds_content_buttons_buy_tokens {
            display: none;
         }

         .rounds_content_buttons_participate:hover {
            background-color: #91EBFF;
            box-shadow: 0px .417vw .7vw rgba(24, 203, 248, 0.5);
         }

         .rounds_content_buttons_participate:active {
            background-color: #199BC5;
            box-shadow: inset 0px .28vw .28vw rgba(0, 0, 0, 0.25);
         }
      }

      a {
         display: inline-block;
         @include size(99.5%, 5.55vw);
         @include flex(center, center);
         border-radius: 2.8vw;
         text-decoration: none;

         .rounds_content_upcoming {
            position: relative;
            z-index: 10;
            cursor: pointer;
            @include size(100%, 100%);
            border-radius: 2.8vw;
            border: .2vw solid $c2;
            @include flex(center, center);

            .rounds_content_upcoming_sup {
               @include flex(center, center);
               @include text($c2, 400, 1.4vw);
               @include size(98.6%, 4.58vw);
               border-radius: 2.8vw;
               border: .2vw solid #41548A;

               span {
                  @include text($c2, 500, 1.75vw);
               }

               .rounds_content_upcoming_sup_timer {
                  margin-top: 0vw;
               }
            }
         }
      }


      .rounds_content_buttons_buy_tokens {
         position: relative;
         z-index: 10;
         margin-top: 3vw;

         .rounds_content_buttons_buy_tokens_title {
            @include flex(space-between, center);
            margin-bottom: 2.1vw;

            h2 {
               @include text($c2, 500, 2.08vw);
            }

            .rounds_content_buttons_buy_tokens_title_color {
               @include flex(flex-start, center);

               .button {
                  @include flex(center, center);
                  cursor: pointer;
                  width: 6vw;
                  padding: .7vw;
                  border-radius: .7vw;
                  border: .14vw solid $c2;
                  background-color: none;
                  @include text($c1, 400, .98vw);
               }

               .button:first-child {
                  margin-right: .7vw;
               }

               .button.active {
                  background-color: $c2;
               }
            }
         }

         .rounds_content_buttons_buy_tokens_content {
            position: relative;
            padding: 1.4vw;
            @include flex(space-between, flex-start);
            height: 520px;

            iframe {
               width: 100%;
               height: 100%;
            }

            .rounds_content_buttons_buy_tokens_left {
               width: 63%;
               height: 100%;
            }

            .rounds_content_buttons_buy_tokens_right {
               width: 34%;
               height: 100%;
            }

            .rounds_content_buttons_buy_tokens_content_loading_block {
               position: absolute;
               top: 0;
               left: 0;
               display: none;
               @include size(100%, 100%);

               .rounds_content_buttons_buy_tokens_content_loading_block_text {
                  font-size: 1.8vw;
                  font-weight: 700;
                  text-align: center;
                  @include size(100%, 100%);
                  margin-top: 20%;
               }
            }

            .rounds_content_buttons_buy_tokens_content_loading_block.active {
               display: block;
            }
         }

         .rounds_content_buttons_buy_tokens_content.white {
            background-color: $c1;

            .rounds_content_buttons_buy_tokens_content_loading_block {
               background-color: $c1;

               .rounds_content_buttons_buy_tokens_content_loading_block_text {
                  color: #161727;
               }
            }
         }

         .rounds_content_buttons_buy_tokens_content.dark {
            background-color: #161727;

            .rounds_content_buttons_buy_tokens_content_loading_block {
               background-color: #161727;

               .rounds_content_buttons_buy_tokens_content_loading_block_text {
                  color: $c1;
               }
            }
         }

         @media (max-width: 1050px) {
            .rounds_content_buttons_buy_tokens_content {
               display: block;
               height: 100%;

               .rounds_content_buttons_buy_tokens_left {
                  width: 100%;
                  height: 400px;
               }

               .rounds_content_buttons_buy_tokens_right {
                  width: 100%;
                  margin-top: 3vw;
                  height: 520px;
               }
            }
         }

         @media (max-width: 600px) {
            .rounds_content_buttons_buy_tokens_content {
               padding: 3vw;
            }
         }
      }

      @media (max-width: 480px) {
         .rounds_content_buttons_buy_tokens {
            margin-top: 12.5vw;

            .rounds_content_buttons_buy_tokens_title {
               flex-direction: column;

               h2 {
                  font-size: 6.25vw;
                  font-weight: 500;
                  margin-bottom: 3.17vw;
               }

               .rounds_content_buttons_buy_tokens_title_color {
                  @include flex(space-between, center);
                  width: 100%;
                  margin-bottom: 3.17vw;

                  .button {
                     cursor: auto;
                     width: 40%;
                     padding: 3.17vw;
                     border-radius: 3.17vw;
                     border: .635vw solid $c2;
                     font-size: 4.44vw;
                  }
               }
            }

            .rounds_content_buttons_buy_tokens_content {
               padding: 3vw;

               .rounds_content_buttons_buy_tokens_right {
                  margin-top: 7vw;
               }

               .rounds_content_buttons_buy_tokens_content_loading_block {

                  .rounds_content_buttons_buy_tokens_content_loading_block_text {
                     font-size: 5vw;
                     margin-top: 100%;
                  }
               }
            }
         }
      }
   }
}