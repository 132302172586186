.loading {
   @include flex(center, center);

   .lds-dual-ring {
      display: inline-block;
      width: 2vw;
      height: 2vw;
   }

   .lds-dual-ring:after {
      content: " ";
      display: block;
      width: 2.4vw;
      height: 2.4vw;
      border-radius: 50%;
      margin-top: .8vw;
      border: .42vw solid #000;
      border-color: #000 transparent #000 transparent;
      animation: lds-dual-ring 1.2s linear infinite;
   }

   @keyframes lds-dual-ring {
      0% {
         transform: rotate(0deg);
      }

      100% {
         transform: rotate(360deg);
      }
   }
}