@media (max-width: 480px) {
   .tokenomics {
      margin-bottom: 25vw;

      .tokenomics_content {

         h1 {
            @include text($c1, 500, 7.8125vw);
            text-align: center;
         }

         .tokenomics_content_image {
            display: block;

            img {
               @include size(93.75vw, 104.6875vw);
               margin: 0;
            }

            ul {
               display: none;
            }
         }

         .tokenomics_content_table {
            position: relative;
            @include size(97.1875vw, 246vw);
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            table {
               position: absolute;
               top: 0;
               left: 0vw;
               @include size(315.3125vw, 100%);

               tr {
                  border-spacing: .7vw .7vw;

                  td:nth-child(2),
                  td:nth-child(3) {
                     padding-top: 1.725vw;
                  }

                  td:nth-child(4) {
                     padding-top: 1.5vw;
                  }

                  td {
                     @include text($c1, 400, 4.375vw);
                     padding: 1.6625vw 0;
                  }

                  td:nth-child(1) {
                     width: 60vw;
                     padding-left: 3.125vw;

                     .text.text::before {
                        content: '';
                        display: inline-block;
                        @include size(4.375vw, 4.375vw);
                        border-radius: 50%;
                        margin-right: 4.375vw;
                        border-width: .625vw;
                     }
                  }

                  td:nth-child(2) {
                     width: 31.25vw;
                     padding-right: 11.25vw;

                  }

                  td:nth-child(3) {
                     width: 31.875vw;
                     padding-right: 11.25vw;
                  }

                  td:nth-child(4) {
                     padding-right: 3.125vw;
                  }
               }

               tr:nth-child(1) {

                  td:nth-child(2),
                  td:nth-child(3),
                  td:nth-child(4) {
                     padding-top: 1.8765vw;
                  }
               }

               tr:nth-child(15) {
                  td:nth-child(1) {
                     width: 22.5vw;
                     padding-left: 7.33vw;
                  }

                  td:nth-child(2),
                  td:nth-child(4) {
                     padding-top: 1.8765vw;
                  }
               }

               tr:first-child {
                  td {
                     @include text($c2, 500, 4.375vw);
                     padding-bottom: 3.125vw;
                  }

                  td::before {
                     content: none;
                  }
               }


               tr:last-child {
                  td {
                     @include text($c1, 500, 4.375vw);
                  }

                  td:first-child {
                     padding-left: 13.5vw;
                  }

                  td::before {
                     content: none;
                  }
               }

               tr:nth-child(2n) {
                  background-color: rgba(0, 209, 255, 0.1);

                  td:last-child {
                     border-radius: 0 3.125vw 3.125vw 0;
                  }

                  td:first-child {
                     border-radius: 3.125vw 0 0 3.125vw;
                  }
               }
            }
         }
      }
   }
}