@media (max-width: 480px) {
   .resources_and_partners {
      bottom: 290vw;
      height: 77vw;

      .resources_and_partners_container {
         flex-direction: column;
         @include flex(flex-start, center);
         width: 93.72vw;
         padding-top: 3.17vw;

         .resources_and_partners_container_left_block {

            h4 {
               font-size: 5vw;
               color: #0B0A3E;
               margin-bottom: 4.7vw;
            }
         }

         .resources_and_partners_container_right_block {
            width: 93.72vw;
            height: 53vw;
            position: relative;

            ul {
               flex-wrap: wrap;
               @include flex(center, center);

               li {
                  position: absolute;

                  a {
                     display: inline-block;

                     img {
                        @include size(100%, 100%);
                     }
                  }
               }
            }

            .resources_and_partners_container_right_block_top {

               ul {

                  li:nth-child(1) {
                     margin-right: 0;
                     top: 0;
                     left: 2vw;

                     a {
                        @include size(21vw, 6.35vw);
                     }
                  }

                  li:nth-child(2) {
                     margin-right: 0;
                     top: 0;
                     left: 35%;

                     a {
                        @include size(6.35vw, 6.35vw);
                     }
                  }

                  li:nth-child(3) {
                     margin-right: 0;
                     top: 0;
                     right: 2vw;

                     a {
                        @include size(20vw, 4.8vw);
                     }
                  }

                  li:nth-child(4) {
                     top: 10.74vw;
                     left: 0;

                     a {
                        @include size(27vw, 6.35vw);
                     }
                  }

                  li:nth-child(5) {
                     top: 32vw;
                     left: 3vw;

                     a {
                        @include size(17.5vw, 6.66vw);
                     }
                  }

                  li:nth-child(6) {
                     top: 32vw;
                     left: 33%;

                     a {
                        @include size(27vw, 6.35vw);
                     }
                  }

                  li:nth-child(7) {
                     top: 0;
                     left: 45%;

                     a {
                        @include size(27vw, 6.35vw);
                     }
                  }

                  li:nth-child(8) {
                     top: 43vw;
                     left: 7%;

                     a {
                        @include size(27vw, 6.35vw);
                     }
                  }
               }
            }

            .resources_and_partners_container_right_block_bottom {
               margin-top: 0;

               ul {

                  li:nth-child(1) {
                     margin-right: 0;
                     top: 23vw;
                     left: 3vw;

                     a {
                        @include size(44vw, 4.76vw);
                     }
                  }

                  li:nth-child(2) {
                     margin-right: 0;
                     top: 11.74vw;
                     right: 40%;

                     a {
                        @include size(18.4vw, 4.76vw);
                     }
                  }

                  li:nth-child(3) {
                     margin-right: 0;
                     top: 9vw;
                     right: 0;

                     a {
                        @include size(22.5vw, 8vw);
                     }
                  }

                  li:nth-child(4) {
                     top: 22.3vw;
                     right: 3vw;
                     margin-right: 0;

                     a {
                        @include size(33vw, 6.35vw);
                     }
                  }

                  li:nth-child(5) {
                     top: 32vw;
                     right: 3vw;

                     a {
                        @include size(23.5vw, 6.35vw);
                     }
                  }

                  li:nth-child(6) {
                     top: 43vw;
                     right: 7%;

                     a {
                        @include size(41vw, 6.35vw);
                     }
                  }

                  li:nth-child(7) {
                     top: 54vw;
                     right: 30%;

                     a {
                        @include size(41vw, 6.35vw);
                     }
                  }
               }
            }
         }
      }
   }
}