footer {
   background: $c6;
   margin-top: 9.78vw;
   position: relative;
   @include size(100%, 37vw);

   img.teleskop {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 3.54vw;
      @include size(40vw, 33.3vw);
   }

   .footer_content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      .footer_content_info {
         padding: 3.47vw 9vw;
         @include flex(flex-start, flex-start);

         .title {
            @extend %textdark;
            background-color: $c2;
            border-radius: 1.39vw;
            @include flex(center, center);
            margin-bottom: 1.67vw;
         }

         ul {
            list-style: none;

            li {
               @include text($c7, 400, 1.1vw);

               span {
                  font-weight: 500;
               }
            }
         }

         .footer_content_info_kyc {
            @include bcsize(16.65vw, 16.2vw, $c8);
            border-radius: .7vw;
            padding: 1.1VW .7vw;
            margin-right: 9.72vw;

            .kyc_and_message {
               @include flex(flex-start, flex-start);

               .title {
                  @include size(3.61vw, 2.08vw);
                  margin-right: 2.64vw
               }

               .kyc_and_message_messages {
                  @include flex(center, center);

                  .message {
                     @include bcsize(2.08vw, 2.08vw, $c2);
                     border-radius: 50%;
                     @include flex(center, center);
                     cursor: pointer;
                  }

                  .kyc_and_message_messages_twitt {
                     margin-right: .7vw;

                     img {
                        @include size(1.25vw, 1vw);
                     }
                  }

                  .kyc_and_message_messages_glob {
                     margin-right: .7vw;

                     img {
                        @include size(1.25vw, 1.25vw);
                     }
                  }

                  .kyc_and_message_messages_medium {
                     border-radius: 50%;
                     @include flex(center, center);

                     img {
                        @include size(2.08vw, 2.08vw);
                     }
                  }
               }
            }

            li {
               @include text($c7, 400, 1.1vw);
               margin-bottom: .8vw;
               position: relative;

               span.questionRu::before,
               span.questionEn::before {
                  position: absolute;
                  top: -2vw;
                  left: 0vw;
                  @include text($c1, 400, .7vw);
                  @include bcsize(10vw, 1vw, $c7);
                  padding: .3vw;
                  display: none;
               }

               span.questionRu::before {
                  content: 'Token address скопирован';
               }

               span.questionRu.active:before {
                  @include flex(center, center);
               }

               span.questionEn::before {
                  content: 'Token address copied';
               }

               span.questionEn.active::before {
                  @include flex(center, center);
               }

               span {
                  @include text($c7, 400, 1.1vw);
                  text-decoration: underline;

                  a {
                     text-decoration: none;
                     color: $c7
                  }
               }

               span.image {
                  margin-left: .7vw;

                  img {
                     @include size(1vw, 1vw);
                     margin-bottom: -.2vw;
                     cursor: pointer;
                  }
               }

               span.text {
                  cursor: pointer;
                  width: 100%;
               }

               img {
                  @include size(8.05vw, 1.95vw);
               }
            }

            li.copy {
               width: 60%;
            }

            li:last-child {
               margin-bottom: 0;
               margin-top: 1vw;
               @include flex(flex-start, center);

               span {
                  margin-right: .5vw;
               }
            }
         }
      }

      .footer_content_info_cont_and_address {
         @include flex(flex-start, center);
         margin-right: 4vw;

         .footer_content_info_contact {
            margin: .5vw 6vw 0 0;

            .title {
               @include size(6.8vw, 2.08vw);
            }

            li {
               margin-top: .8vw;
            }

            li:first-child {
               margin-top: 0;
            }
         }

         .footer_content_info_address {
            margin-top: .9vw;

            .footer_content_info_address_address {

               .title {
                  @include size(6vw, 2.08vw);
               }
            }

            .footer_content_info_address_address_mobile {
               display: none;
            }

            .footer_content_info_address_we {
               position: relative;
               height: 5.3vw;

               .title {
                  margin-top: 3.66vw;
                  @include size(8.72vw, 2.08vw);
               }

               .title.en {
                  width: 6vw;
               }

               ul {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  @include flex(flex-start, center);

                  li {
                     height: 1.4vw;
                     margin-right: .35vw;
                     @include flex(center, center);

                     a {
                        height: 1.4vw;

                        img {
                           @include size(100%, 100%)
                        }
                     }
                  }

                  li:last-child {
                     margin-right: 0;
                  }

                  li:nth-child(1) {
                     width: 6.9vw;
                  }

                  li:nth-child(2) {
                     width: 4.64vw;
                  }

                  li:nth-child(3) {
                     width: 8.08vw;
                  }

                  li:nth-child(4) {
                     width: 5.2vw;
                  }

                  li:nth-child(5) {
                     width: 3.51vw;
                  }
               }
            }
         }
      }

      .footer_content_info_address_we_mobile {
         display: none;
      }

      .footer_content_info_join {
         margin-top: .9vw;
         width: 18.9vw;

         .title {
            @include size(12.2vw, 2.08vw);
         }

         .footer_content_info_join_flex {
            @include flex(space-between, center);

            .footer_content_info_join_flex_leng {

               ul {
                  li {
                     margin-top: .5vw;

                     a {
                        color: #677c9b;
                        text-decoration: none;
                     }
                  }

                  li:first-child {
                     color: $c1;
                     margin-top: 0;
                  }
               }
            }
         }

         .footer_content_info_join_mobile {
            display: none;
         }
      }

      .footer_content_menu {
         @include flex(space-between, center);
         padding: 1.736vw 9vw;
         border-top: 0.139vw solid $c2;
         border-bottom: 0.139vw solid $c2;

         .footer_content_menu_logo {
            @include text($c1, 400, 1.736vw);
            font-family: 'Distant Galaxy';
            font-style: normal;
         }

         ul {
            list-style: none;
            @include flex(space-between, center);

            li {
               margin-right: 1.736vw;
               @extend %text400;
               cursor: pointer;
               transition: color .2s ease-in;

               a {
                  text-decoration: none;
                  color: $c1;
               }
            }

            li:last-child {
               margin: 0;
            }

            li:hover {
               color: $c2;
            }
         }

         .footer_content_menu_privacy {
            @extend %text400;
            text-decoration: underline;
         }
      }

      .footer_content_text {
         padding: 1.74vw 9vw;

         p {
            @include text($c8, 400, 0.7vw)
         }
      }

      .footer_content_text.password {
         padding: 1.74vw 9vw;

         p {
            @include text($c8, 400, 1.2vw)
         }
      }
   }
}

footer.footer {
   height: 38vw;
}