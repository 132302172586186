@media (max-width: 480px) {
   .loading {
      .lds-dual-ring {
         display: inline-block;
         width: 7vw;
         height: 7vw;
      }

      .lds-dual-ring:after {
         content: " ";
         display: block;
         width: 9vw;
         height: 9vw;
         border-radius: 50%;
         margin-top: 2vw;
         border: 1vw solid #000;
         border-color: #000 transparent #000 transparent;
         animation: lds-dual-ring 1.2s linear infinite;
      }

      @keyframes lds-dual-ring {
         0% {
            transform: rotate(0deg);
         }

         100% {
            transform: rotate(360deg);
         }
      }
   }
}