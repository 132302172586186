.tokenomics {
   margin-bottom: 8.68vw;

   .tokenomics_content {

      h1 {
         @extend %text4050;
      }

      .tokenomics_content_image {
         @include flex(flex-start, center);
         margin-bottom: 3.26vw;

         img {
            @include size(38.9vw, 44.58vw);
            margin: 0 10vw 0 7.8vw;
         }

         ul {
            list-style: none;
            @extend %text400;

            li {
               margin-bottom: 1.1vw;
               display: flex;
               align-items: center;
            }

            li:last-child {
               margin-bottom: 0;
            }

            li::before {
               content: '';
               display: inline-block;
               @include size(1.39vw, 1.39vw);
               border-radius: 50%;
               margin-right: .56vw;
            }

            li:nth-child(1)::before {
               border: .2vw solid #F895C8;
            }

            li:nth-child(2)::before {
               border: .2vw solid #FE6601;
            }

            li:nth-child(3)::before {
               border: .2vw solid #B3B3B3;
            }

            li:nth-child(4)::before {
               border: .2vw solid #F5B902;
            }

            li:nth-child(5)::before {
               border: .2vw solid#5490C9;
            }

            li:nth-child(6)::before {
               border: .2vw solid #93EF44;
            }

            li:nth-child(7)::before {
               border: .2vw solid #FC00FC;
            }

            li:nth-child(8)::before {
               border: .2vw solid #F30000;
            }

            li:nth-child(9)::before {
               border: .2vw solid #606163;
            }

            li:nth-child(10)::before {
               border: .2vw solid #977201;
            }

            li:nth-child(11)::before {
               border: .2vw solid #235A8C;
            }

            li:nth-child(12)::before {
               border: .2vw solid#028432;
            }

            li:nth-child(13)::before {
               border: .2vw solid #00FAFB;
            }
         }
      }

      .tokenomics_content_table {

         table {
            border-collapse: separate;
            border-spacing: 0;
            width: 100%;

            tr {
               border-spacing: .7vw .7vw;

               td:nth-child(2),
               td:nth-child(3) {
                  padding-top: .65vw;
               }

               td:nth-child(4) {
                  padding-top: .6vw;
               }

               td {
                  @extend %text400;
                  border: none;
                  padding: .417vw 0;
                  vertical-align: top;
               }

               td:nth-child(1) {
                  width: 17.5vw;
                  padding-left: 4.17vw;

                  .text.text::before {
                     content: '';
                     display: inline-block;
                     @include size(1.39vw, 1.39vw);
                     border-radius: 50%;
                     margin-right: 1.39vw;
                  }
               }

               td:nth-child(2) {
                  width: 6.95vw;
                  padding-right: 4.17vw;

               }

               td:nth-child(3) {
                  width: 7.95vw;
                  padding-right: 2.77vw;
               }

               td:nth-child(4) {
                  padding-right: 4.17vw;
               }
            }

            tr:nth-child(1) {

               td:nth-child(2),
               td:nth-child(3),
               td:nth-child(4) {
                  padding-top: .417vw;
               }
            }

            tr:nth-child(15) {
               td:nth-child(1) {
                  width: 5vw;
                  padding-left: 7.33vw;
               }

               td:nth-child(2),
               td:nth-child(4) {
                  padding-top: .417vw;
               }
            }

            tr:nth-child(2) {
               td {
                  .text::before {
                     border: .2vw solid #F895C8;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(3) {
               td {
                  .text::before {
                     border: .2vw solid #FE6601;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(4) {
               td {
                  .text::before {
                     border: .2vw solid #B3B3B3;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(5) {
               td {
                  .text::before {
                     border: .2vw solid #F5B902;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(6) {
               td {
                  .text::before {
                     border: .2vw solid #5490C9;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(7) {
               td {
                  .text::before {
                     border: .2vw solid #93EF44;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(8) {
               td {
                  .text::before {
                     border: .2vw solid #FC00FC;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(9) {
               td {
                  .text::before {
                     border: .2vw solid #F30000;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(10) {
               td {
                  .text::before {
                     border: .2vw solid #606163;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(11) {
               td {
                  .text::before {
                     border: .2vw solid #977201;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(12) {
               td {
                  .text::before {
                     border: .2vw solid #235A8C;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(13) {
               td {
                  .text::before {
                     border: .2vw solid #028432;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }

            tr:nth-child(14) {
               td {
                  .text::before {
                     border: .2vw solid #00FAFB;
                  }

                  .text {
                     display: inline-block;
                     @include flex(flex-start, center)
                  }
               }
            }


            tr:first-child {
               td {
                  @include text($c2, 500, 1.1vw);
                  padding-bottom: .7vw;
               }

               td::before {
                  content: none;
               }
            }

            tr:last-child {
               td {
                  @extend %text500;
               }

               td:first-child {
                  padding-left: 7.5vw;
               }

               td::before {
                  content: none;
               }
            }

            tr:nth-child(2n) {
               background-color: rgba(0, 209, 255, 0.1);

               td:last-child {
                  border-radius: 0 .7vw .7vw 0;
               }

               td:first-child {
                  border-radius: .7vw 0 0 .7vw;
               }
            }
         }
      }
   }
}