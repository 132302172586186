@media (max-width: 480px) {
   .strategy {
      margin-bottom: 137.4375vw;
      position: relative;
      z-index: 10;

      .fon {
         @include size(110.31vw, 91.45vw);
         position: absolute;
         top: -7vw;
         right: -32vw;
         z-index: 1;
      }

      h1 {
         @include text($c1, 500, 7.8125vw);
         text-align: center;
         margin-bottom: 6.25vw;
      }

      .strategy_content {
         flex-direction: column;
         @include flex(flex-start, flex-start);

         ul {
            list-style: none;

            li {
               @include text($c1, 400, 4.375vw);
            }
         }

         .strategy_content_left {
            margin-bottom: 25vw;

            h2 {
               @include text($c2, 700, 6.25vw);
               margin-bottom: 6.25vw;
               text-align: center;
               display: block;
               width: 93.75vw;
            }

            .strategy_content_left_flex {
               position: relative;
               z-index: 100;

               img {
                  height: 145vw;
                  margin-left: 7.1875vw;
               }

               ul {
                  position: absolute;
                  left: 35vw;
                  top: 1.253vw;

                  li {
                     @include size(48.94375vw, 11.903vw);
                     margin-bottom: 15.8vw;
                     line-height: 5.3125vw;
                  }

                  li:nth-child(4),
                  li:nth-child(5) {
                     margin-bottom: 10.5vw;
                     @include size(48.94375vw, 17.540625vw);
                  }

                  li:last-child {
                     margin-bottom: 0;
                  }
               }
            }
         }

         .strategy_content_right {
            position: relative;

            h2 {
               @include text($c4, 700, 6.25vw);
               text-align: center;
               display: block;
               width: 93.75vw;
            }

            .strategy_content_right_flex {
               position: absolute;
               top: 20vw;
               left: 0vw;
               @include size(97.1875vw, 101.2vw);
               overflow: auto;
               -ms-overflow-style: none;
               scrollbar-width: none;

               .strategy_content_right_flex_content {

                  img {
                     display: none;
                  }

                  img.etaps {
                     display: block;
                     @include size(287.5vw, 15.3125vw);
                  }

                  .strategy_content_right_flex_block {
                     @include flex(flex-start, flex-start);
                     margin-top: 2vw;

                     ul.top,
                     ul.bot {
                        @include flex(flex-start, flex-start);

                        li {
                           @include size(41.5625vw, 83.4375vw);
                           line-height: 7.3125vw;
                           padding: 0 2.5vw 0 1.25vw;
                           margin-left: 3.125vw;
                        }

                        li:nth-child(1) {
                           margin-right: 0;
                           margin-left: 0;
                        }

                        li:nth-child(2) {
                           margin-right: 0;
                        }
                     }

                     ul.top {
                        position: inherit;
                        margin-right: 3.125vw;

                        li:nth-child(1) {
                           background: linear-gradient(360deg, rgba(235, 17, 189, 0.5) 0%, rgba(235, 17, 189, 0) 100%);
                        }

                        li:nth-child(2) {
                           background: linear-gradient(360deg, rgba(196, 51, 200, 0.5) 0%, rgba(235, 17, 189, 0) 100%);
                        }

                        li:nth-child(3) {
                           background: linear-gradient(360deg, rgba(161, 82, 210, 0.5) 0%, rgba(235, 17, 189, 0) 100%);
                        }
                     }

                     ul.bot {
                        position: inherit;
                        margin-right: 3.125vw;

                        li:nth-child(1) {
                           background: linear-gradient(360deg, rgba(125, 113, 220, 0.5) 0%, rgba(235, 17, 189, 0) 100%);
                        }

                        li:nth-child(2) {
                           background: linear-gradient(360deg, rgba(88, 145, 230, 0.5) 0%, rgba(235, 17, 189, 0) 100%);
                        }

                        li:nth-child(3) {
                           background: linear-gradient(360deg, rgba(52, 177, 240, 0.5) 0%, rgba(235, 17, 189, 0) 100%);
                        }
                     }
                  }
               }
            }
         }
      }
   }
}