@media (max-width: 480px) {
   header {
      display: none;
   }

   header.header {
      background: $c6;
      @include size(100%, 16vw);
      margin: 0;
      @include flex(center, center);

      .header_content.password {
         @include flex(space-between, center);
         width: 82%;

         a {
            .header_content_come_main {
               font-size: 4vw
            }
         }

         h1 {
            font-size: 5vw;
            margin: 0;
            padding: 0
         }

         .header_content_language {

            .language {
               @include bcsize(6vw, 6vw, none);
               margin-right: 3vw;
               font-size: 3vw;
               padding: 0;
            }

            .language.active {
               color: $c3;
               background-color: $c2;
            }

            .language:last-child {
               margin: 0;
            }
         }
      }
   }
}