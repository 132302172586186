@media (max-width: 480px) {
   .main_screen {
      @include size(100%, 186.875vw);

      .main_screen_content {
         display: block;
         position: relative;
         @include size(100%, 100%);

         .main_screen_content_text {

            .main_screen_content_text_mobile {
               @include flex(space-between, center);
               margin-top: 6.25vw;

               .main_screen_content_text_mobile_person {
                  background: url('../../../public/images/personalAccountMobile.svg') center no-repeat;
                  @include size(5.7312vw, 5.76vw);
                  background-size: cover;
                  cursor: pointer;
               }

               h1 {
                  @include text($c1, 400, 8.96vw);
                  text-align: center;
                  margin: 0;
               }

               .main_screen_content_text_mobile_burger {
                  background: url('../../../public/images/navMenuMobile.svg') center no-repeat;
                  @include size(7.04vw, 5.76vw);
                  background-size: contain;
                  cursor: pointer;
               }
            }

            h2 {
               display: block;
               position: absolute;
               top: 100.94vw;
               left: 0;
               @include text($c1, 500, 8vw);
               text-align: center;

               span {
                  font-weight: 700;
               }
            }

            .main_screen_content_text_buttons {
               position: absolute;
               bottom: 0;
               left: 8.5vw;
               display: flex;
               flex-direction: column;
               @include flex(center, center);

               .buttons {
                  @include size(75vw, 17.1875vw);
                  border-radius: 11vw;
               }

               .main_screen_content_text_buttons_invest {
                  @include text($c3, 500, 4.48vw);
                  margin-bottom: 3.125vw;
               }

               .main_screen_content_text_buttons_go_website {
                  @include size(75vw, 17.1875vw);
                  border-radius: 11vw;
                  @include text($c1, 500, 4.48vw);

                  img {
                     @include size(75vw, 17.1875vw);
                     border-radius: 11.2vw;
                  }
               }

               .main_screen_content_text_buttons_go_website:before {
                  border-radius: 11.2vw;
               }
            }
         }

         .main_screen_content_image {
            position: absolute;
            top: 20vw;
            left: -2.8vw;
            @include size(93.76vw, 70.08vw);

            img {
               @include size(103%, 108%);
            }

            .main_screen_content_image_video {
               position: absolute;
               top: 43%;
               left: 43%;

               .main_screen_content_image_video_button {
                  @include size(17.1875vw, 11.875vw);
                  border-radius: 2.5vw;

                  img {
                     @include size(5vw, 4.325vw);
                     margin-left: 1vw;
                  }
               }

               .main_screen_content_image_video_button::before {
                  width: 4.5vw;
                  background: rgba(255, 255, 255, 0.6);
                  transform: translateX(-8vw) skew(-40deg);
                  animation: move 5s 1s infinite;
               }

               @keyframes move {
                  0% {
                     transform: translateX(-15vw) skew(-40deg)
                  }

                  100% {
                     transform: translateX(40vw) skew(-40deg)
                  }
               }
            }
         }
      }
   }
}