@media (max-width: 480px) {
   footer {
      margin-top: 45.625vw;
      @include size(100%, 290vw);

      img.teleskop {
         position: absolute;
         z-index: 1;
         right: -30.25vw;
         top: 185.625vw;
         @include size(128vw, 98.75vw);
      }

      .footer_content {

         .footer_content_info {
            padding: 0;
            padding-top: 9.375vw;
            display: block;

            .title {
               position: relative;
               display: block;
               font-size: 4.375vw;
               border-radius: 0px 6.25vw 6.25vw 0px;
               margin-bottom: 6.25vw;
               @include flex(center, center);
            }

            .title::before {
               content: "";
               display: block;
               position: absolute;
               top: 4.0625vw;
               width: 100%;
               height: .625vw;
               background: $c2;
            }

            ul {
               li {
                  font-size: 4.375vw;
               }
            }

            .footer_content_info_kyc {
               position: relative;
               @include bcsize(60.125vw, 70.25vw, $c8);
               border-radius: 3.125vw;
               padding: 3.125vw 7.4375vw;
               margin: 0 auto;
               z-index: 1000;

               .kyc_and_message {
                  position: absolute;
                  @include flex(center, center);
                  flex-direction: column;
                  width: 80%;

                  .title {
                     @include size(15vw, 8.125vw);
                     margin-bottom: 55vw;
                     border-radius: 6.25vw;
                     font-size: 4.375vw;
                     z-index: 1;
                     margin-left: 3vw;
                  }

                  .title::before {
                     content: none;
                  }

                  .kyc_and_message_messages {
                     @include flex(center, center);

                     .message {
                        @include bcsize(6.25vw, 6.25vw, $c2);
                        border-radius: 50%;
                        @include flex(center, center);
                        cursor: pointer;
                     }

                     .kyc_and_message_messages_twitt {
                        margin-right: 3.125vw;

                        img {
                           @include size(3.75vw, 2.916vw);
                        }
                     }

                     .kyc_and_message_messages_glob {
                        margin-right: 3.125vw;

                        img {
                           @include size(3.75vw, 3.75vw);
                        }
                     }

                     .kyc_and_message_messages_medium {

                        img {
                           @include size(6.25vw, 6.25vw);
                        }
                     }
                  }
               }

               ul {
                  position: absolute;
                  top: 14vw;
                  left: 13%;

                  li {
                     @include text($c7, 400, 4.375vw);
                     margin-bottom: 3.6vw;
                     text-align: center;

                     span.questionRu::before,
                     span.questionEn::before {
                        position: absolute;
                        top: -6.5vw;
                        left: 6vw;
                        font-size: 3vw;
                        @include bcsize(75%, 5vw, $c7);
                        padding: .3vw;
                        display: none;
                     }

                     span.questionRu::before {
                        content: 'Token address скопирован';
                     }

                     span.questionRu.active:before {
                        @include flex(center, center);
                     }

                     span.questionEn::before {
                        content: 'Token address copied';
                     }

                     span.questionEn.active::before {
                        @include flex(center, center);
                     }

                     span {
                        @include text($c7, 400, 4.375vw);
                     }

                     img {
                        @include size(28.35vw, 6.875vw);
                     }

                     span.image {
                        margin-left: 2vw;

                        img {
                           @include size(3.5vw, 3.5vw);
                           margin-bottom: 0vw;
                           cursor: auto;
                        }
                     }

                     span.text {
                        cursor: auto;
                     }
                  }

                  li.copy {
                     width: 100%;
                  }

                  li:last-child {
                     margin-top: 0;
                     @include flex(flex-start, center);

                     span {
                        margin-right: 1.875vw;
                     }
                  }
               }
            }
         }

         .footer_content_info_cont_and_address {
            @include flex(flex-start, center);

            .footer_content_info_address {


               .footer_content_info_address_we,
               .footer_content_info_address_address {
                  display: none;
               }

               .footer_content_info_address_address_mobile {
                  display: block;
                  margin-top: 11vw;

                  ul {
                     li {
                        margin-top: 3.6vw;
                     }

                     li:first-child {
                        margin-top: 0;
                     }
                  }
               }
            }

            .footer_content_info_contact {
               margin-top: 6.25vw;

               .title {
                  @include size(27.5vw, 8.75vw);
               }

               .title::before {
                  width: 74vw;
                  left: 27.5vw;
               }

               ul {
                  margin-left: 3.125vw;

                  li {
                     margin-top: 3.6vw;
                  }

                  li:first-child {
                     margin-top: 0;
                  }

                  li:last-child {
                     display: none;
                  }
               }
            }

            margin-right: 4vw;
         }

         .footer_content_info_join {
            margin: 0;
            width: 100%;
            margin-top: 6.25vw;

            .title {
               @include size(49.0625vw, 8.75vw);
            }

            .title::before {
               width: 52vw;
               left: 49.0625vw;
            }

            .footer_content_info_join_flex {
               display: none;
            }

            .footer_content_info_join_mobile {
               padding: 0 10vw;
               display: block;
               @include flex(space-between, center);

               .footer_content_info_join_mobile_flex {
                  @include flex(flex-start, center);

                  a {
                     border-radius: 50%;
                     @include size(6.25vw, 6.25vw);

                     img {
                        border-radius: 50%;
                     }
                  }

                  .text {
                     @include text($c1, 400, 3.125vw)
                  }

                  .leng {
                     @include size(12.5vw, 12.8125vw);
                     flex-direction: column;
                     @include flex(space-between, center);

                     img {
                        @include size(6.25vw, 6.25vw)
                     }
                  }

                  .telegram {
                     @include size(12.5vw, 12.8125vw);
                     flex-direction: column;
                     @include flex(space-between, center);

                     img {
                        @include size(6.25vw, 6.25vw)
                     }
                  }
               }
            }
         }

         .footer_content_info_address_we_mobile {
            display: block;
            margin-top: 6.25vw;
            padding-bottom: 6.25vw;

            .title {
               width: 34.6875vw;
               @include flex(flex-start, center);
               padding-left: 3.225vw;
            }

            .title::before {
               width: 72vw;
               left: 34.6875vw;
            }

            .title.en {
               width: 15.6875vw;
            }

            .title.en::before {
               width: 91vw;
               left: 15.6875vw;
            }

            .footer_content_info_address_we_mobile_top,
            .footer_content_info_address_we_mobile_bottom {
               padding: 0 4.5vw;
               height: 6.34vw;

               a {
                  display: inline-block;
                  height: 6.34vw;
               }
            }

            .footer_content_info_address_we_mobile_top {
               @include flex(space-between, center);
               margin-bottom: 1.6vw;

               a:nth-child(1) {
                  img {
                     @include size(31vw, 6.34vw)
                  }
               }

               a:nth-child(2) {
                  img {
                     @include size(21vw, 6.34vw)
                  }
               }

               a:nth-child(3) {
                  img {
                     @include size(36.5vw, 6.34vw)
                  }
               }
            }

            .footer_content_info_address_we_mobile_bottom {
               @include flex(center, center);

               a:nth-child(1) {
                  margin-right: 1vw;

                  img {
                     @include size(23.5vw, 6.34vw)
                  }
               }

               a:nth-child(2) {
                  img {
                     @include size(15.8vw, 6.34vw)
                  }
               }
            }
         }

         .footer_content_menu {
            padding: 3.125vw;
            border-top: .625vw solid $c2;
            border-bottom: .625vw solid $c2;

            .footer_content_menu_logo {
               font-size: 7.8125vw;
            }

            ul {
               display: none;
            }

            .footer_content_menu_privacy {
               font-size: 4.375vw;
            }
         }

         .footer_content_text {
            padding: 3.125vw;

            p {
               @include text($c8, 400, 3.125vw);
               text-align: center;
            }
         }

         .footer_content_text.password {
            padding: 3.125vw;
            padding-top: 0vw;

            p {
               @include text($c8, 400, 4vw);
               text-align: center;
            }
         }
      }
   }

   footer.footer {
      height: 290vw;
   }
}