@media (max-width: 480px) {
   .overflow_modal {
      padding-top: 40%;

      .overflow_modal_content {
         @include size(80vw, 40vw);
         padding: 6.35vw;
         border-radius: 3.17vw;

         .overflow_modal_content_content {
            padding-top: 8vw;

            h3 {
               font-size: 5.5vw;
               font-weight: 700;
               margin-bottom: 5vw;
            }

            .overflow_modal_content_content_text {

               a.mobile {
                  display: block;
                  font-size: 4.5vw;
               }

               a {
                  display: none;
               }
            }

            img {
               position: absolute;
               top: -1vw;
               right: -1vw;
               @include size(5vw, 5vw);
               cursor: pointer;
            }
         }
      }
   }
}