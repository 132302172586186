@media (max-width: 480px) {
   .rounds {
      position: relative;
      margin-bottom: 20vw;

      .fon {
         @include size(125.3125vw, 130.625vw);
         position: absolute;
         top: 92.7vw;
         right: -29vw;
         z-index: 1;
      }

      .rounds_content {

         h1 {
            @include text($c1, 500, 7.8125vw);
            text-align: center;
            margin-bottom: 12.5vw;
            display: block;
            padding: 0 6.25vw;
         }

         .rounds_content_text {
            display: block;
            text-align: center;
            margin-bottom: 18.75vw;

            h2:first-child {
               @include text($c2, 500, 6.25vw);
               margin-bottom: 3.125vw;
            }

            h2:last-child {
               @include text($c2, 500, 4.375vw);
            }
         }

         .rounds_content_text.two {
            margin: 12.5vw 0 6.25vw 0;
         }

         .rounds_content_round {

            img.web {
               display: none;
            }

            img.mobile {
               display: block;
               @include size(93.75vw, 20.3125vw);
               margin-bottom: 6.25vw;
            }

            ul.mobile {
               display: block;

               li {
                  @include flex(flex-start, center);
                  @include bcsize(92.1875vw, 9.375, rgba(0, 209, 255, 0.1));
                  border-radius: 3.125vw;
                  margin-bottom: 1.5625vw;
                  padding: 1.5625vw 0 1.5625vw 1.5625vw;

                  .mobile_prise {
                     @include flex(flex-start, center);
                     @include text($c1, 400, 4.375vw);
                     width: 42.5vw;

                     .mobile_prise_circle {
                        @include size(4.6vw, 4.6vw);
                        border-radius: 50%;
                        border: .625vw solid #93EF44;
                        margin-right: 1.875vw;
                     }
                  }

                  .mobile_number {
                     margin-right: 4.875vw;
                     @include text($c1, 400, 4.375vw);
                  }

                  .mobile_text {
                     @include text(#93EF44, 500, 4.375vw);
                  }
               }
            }

            ul.top {
               @include flex(flex-start, center);
               top: -9.375vw;

               li {
                  @include text($c2, 500, 4.375vw);
               }

               li:nth-child(1) {
                  margin: 0 15vw 0 10.61vw;
               }

               li:nth-child(2) {
                  margin-right: 12.5vw;
               }

            }

            ul.bot {
               display: none;
            }
         }

         .rounds_content_buttons {
            @include flex(center, center);
            margin-top: 12.5vw;

            .rounds_content_buttons_participate {
               display: none;
            }

            .rounds_content_buttons_buy_tokens {
               display: block;
               @include bcsize(75vw, 17.1875vw, $c2);
               border-radius: 11vw;
               @include flex(center, center);
               text-align: center;
               @include text($c3, 500, 4.375vw);
            }

            .rounds_content_buttons_buy_tokens:active {
               background-color: #199BC5;
            }
         }

         a {
            @include size(92.75vw, 20.3125vw);
            border-radius: 12.5vw;

            .rounds_content_upcoming {
               @include size(92.75vw, 20.3125vw);
               border-radius: 12.5vw;
               border: .625vw solid $c2;
               @include flex(center, center);

               .rounds_content_upcoming_sup {
                  @include flex(center, center);
                  flex-direction: column;
                  font-size: 4.4vw;
                  @include size(89vw, 17.1875vw);
                  border-radius: 12.5vw;
                  border: .625vw solid #41548A;

                  span {
                     @include text($c2, 500, 4vw);
                  }

                  .rounds_content_upcoming_sup_timer {
                     margin-top: 1vw;
                  }
               }
            }
         }
      }
   }
}