.overflow_modal {
   position: fixed;
   top: 0;
   left: 0;
   @include size(100%, 100%);
   @include flex(center, flex-start);
   padding-top: 10%;
   z-index: 100;
   background-color: rgba(0, 0, 0, .6);
   opacity: 0;
   pointer-events: none;
   transition: opacity .4s ease;
   overflow: auto;

   .overflow_modal_content {
      @include size(30vw, 10vw);
      transform: scale(.1);
      background-color: $c1;
      transition: transform .4s ease;
      padding: 2.1vw;
      border-radius: .7vw;

      .overflow_modal_content_content {
         position: relative;

         h3 {
            font-weight: 500;
            font-size: 1.8vw;
            text-align: center;
            color: $c3;
            margin-bottom: 2.1vw;
         }

         .overflow_modal_content_content_text {
            text-align: center;

            a.mobile {
               display: none;
            }

            a {
               font-size: 1.1vw;
               color: $c2;
               font-weight: 500;
            }
         }

         img {
            position: absolute;
            top: -1vw;
            right: -1vw;
            @include size(1.1vw, 1.1vw);
            cursor: pointer;
         }
      }
   }
}

.overflow_modal.active {
   opacity: 1;
   pointer-events: all;

   .overflow_modal_content {
      transform: scale(1);
   }
}