@import './development/mixins.scss',
'./development/templates.scss',
'./development/variables.scss',
'./page-styles/footer.scss',
'./page-styles/header.scss',
'./page-styles/main-screen.scss',
'./page-styles/about-progect.scss',
'./page-styles/statics.scss',
'./page-styles/team.scss',
'./page-styles//tokenomics.scss',
'./page-styles//strategy.scss',
'./page-styles//rounds.scss',
'./media/main-screen-media.scss',
'./media/header-media.scss',
'./media/about-progect-media.scss',
'./media/statics-media.scss',
'./media/strategy-media.scss',
'./media/tokenomics-media.scss',
'./media/rounds-media.scss',
'./media/team-media.scss',
'./media/footer-media.scss',
'./media/mobile-navbar-media.scss',
'./modal/registration.scss',
'./modal/registration-media.scss',
'./loading/loading.scss',
'./media/loading-media.scss',
'./pages/new-password-page.scss',
'./pages/new-password-page-media.scss',
'./modal/modal-video.scss',
'./page-styles/resources-and-partners.scss',
'./media/resources-and-partners.scss',
'./page-styles/statistics/modalStatistics.scss',
'./media/statistics/modalStatistics.scss';

*,
body {
  @include margins(0, 0);
}

@font-face {
  font-family: 'Distant Galaxy';
  src: local('Distant Galaxy'),
    url('../../public/fonts/DISTGRG_.ttf');
}

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-position: center;

  .wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: url('../../public/images/backgroundDocument.jpg') top no-repeat;
    background-size: cover;

    .container {
      width: 81vw;
      margin: 0 auto;
    }

    .navbar {
      position: absolute;
      display: none;
    }
  }
}

code {
  font-family: 'Rubik', sans-serif;
}

@media (max-width: 480px) {
  body {
    .wrapper {
      position: relative;

      .container {
        min-width: 93.75vw;
        margin: 0 auto;
      }

      .navbar {
        display: block;
      }
    }
  }
}