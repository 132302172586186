.overflow_video {
   position: fixed;
   top: 0;
   left: 0;
   @include size(100vw, 100vh);
   @include flex(center, flex-start);
   z-index: 10000;
   padding-top: 10%;
   background: rgba(0, 0, 0, 0.6);
   transition: all .4s ease;
   opacity: 0;
   pointer-events: none;

   .modal {
      position: relative;
      @include size(49.5vw, 27.9vw);
      background-color: black;

      iframe {
         @include size(100%, 100%)
      }

      .close_modal {
         position: absolute;
         top: 0;
         left: 0;
      }
   }
}

.overflow_video.active {
   opacity: 1;
   pointer-events: all;
}

@media (max-width: 480px) {
   .overflow_video {
      padding-top: 30%;

      .modal {
         @include size(90%, 52vw);
      }
   }
}