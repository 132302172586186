@mixin text($c, $fw, $fs) {
   color: $c;
   font-weight: $fw;
   font-size: $fs;
}

@mixin size($w, $h) {
   width: $w;
   height: $h;
}

@mixin bcsize($w, $h, $b) {
   width: $w;
   height: $h;
   background: $b;
}

@mixin flex($j, $a) {
   display: flex;
   justify-content: $j;
   align-items: $a;
}

@mixin margins($p, $m) {
   padding: $p;
   margin: $m;
}