@media (max-width: 480px) {
   .page_password {
      .modal {
         height: 100vw;
         @include flex(center, center);

         .modal_container {
            width: 80%;

            .modal_container_text {
               font-size: 10vw;
               margin: 0 0 5vw 0;
            }

            .error {
               height: 10vw;
               border-radius: 5vw;
               border: .270vw solid #F30000;
               @include text(#F30000, 400, 4vw);
               margin-top: 4vw
            }

            .get_true {
               height: 10vw;
               border-radius: 5vw;
               border: .270vw solid #03b409;
               @include text(#03b409, 400, 4vw);
               margin-top: 4vw
            }

            form {
               position: relative;

               input {
                  height: 10vw;
                  border-radius: 5vw;
                  border: .270vw solid $c2;
                  padding-left: 4vw;
                  font-size: 4vw;
               }

               input:first-child {
                  margin-bottom: 3vw;
               }

               input::placeholder {
                  font-size: 4vw;
               }

               input.pass {
                  margin: 0
               }

               input[type="submit"] {
                  height: 10vw;
                  border-radius: 5vw;
                  margin-top: 4vw;
               }
            }
         }
      }
   }
}