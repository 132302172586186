.overflow {
   position: fixed;
   top: 0;
   left: 0;
   @include size(100vw, 100vh);
   @include flex(center, flex-start);
   padding-top: 2%;
   z-index: 10000;
   background: rgba(0, 0, 0, 0.6);
   transition: all .4s ease;
   opacity: 0;
   pointer-events: none;

   .overflow_content {
      flex-direction: column;
      @include flex(center, center);
      overflow: auto;

      h2 {
         @include text($c1, 400, 3.47vw);
         font-family: 'Distant Galaxy';
         margin-bottom: 1.39vw;
         transform: scale(0.5);
         transition: transform .4s ease;
      }

      h2.active {
         transform: scale(1);
      }

      .overflow_content_reg {
         @include bcsize(100%, 0, $c1);
         border-radius: .7vw;
         margin-top: 1.39vw;
         @include flex(center, center);
         @include text($c3, 400, 1.1vw);
         transition: all .4s ease;
         opacity: 0;

         span {
            font-weight: 500;
            cursor: pointer;
         }
      }

      .overflow_content_reg.active {
         @include bcsize(100%, 4.17vw, $c1);
         opacity: 1;
      }

      .overflow_content_privat {
         @include text($c7, 400, 1.1vw);
         text-align: center;
         margin-top: 1.39vw;
         transform: scale(0.5);
         transition: transform .4s ease;

         span {
            text-decoration: underline;
            cursor: pointer;
         }
      }

      .overflow_content_privat.active {
         transform: scale(1);
      }

      .modal {
         @include size(34.72vw, 30.83vw);
         background-color: $c1;
         border-radius: .7vw;
         @include flex(center, flex-start);
         transform: scale(0.5);
         transition: transform .4s ease;

         .close_modal {
            display: none;
         }

         .modal_container {
            width: 100%;
            padding: 0 4.86vw;

            .modal_container_message {
               width: 100%;
               @include flex(center, center);
               margin-top: 8.68vw;

               .modal_container_message_message {
                  @include size(91.67%, 3.5vw);
                  border-radius: 2.43vw;
                  border: .139vw solid #93EF44;
                  @include flex(center, center);
                  text-align: center;
               }

               .modal_container_message_message.en {
                  font-size: 1.1vw;
               }
            }

            .modal_container_text {
               @include text($c3, 500, 2.8vw);
               text-align: center;
               margin: 2.8vw 0 1.8vw 0;
            }

            p {
               @include text($c3, 400, 1.1vw);
               text-align: center;
               margin-bottom: 2.08vw;
            }

            .flex {
               @include flex(center, center);

               .modal_container_password {
                  text-align: center;
                  cursor: pointer;
                  @include text($c3, 400, 1.1vw);
                  margin: 2.8vw;
               }

               .modal_container_password.get {
                  margin-top: 8.68vw;
               }
            }

            .error {
               @include size(100%, 3.5vw);
               border-radius: 2.43vw;
               border: .139vw solid #F30000;
               @include text(#F30000, 400, 1.1vw);
               @include flex(center, center);
               text-align: center;
               margin-top: 1.39vw
            }

            form {
               position: relative;

               input {
                  @include size(91.67%, 3.5vw);
                  border-radius: 2.43vw;
                  border: .139vw solid $c2;
                  outline: none;
                  padding-left: 2.08vw;
                  @include text($c3, 600, 1.1vw);
               }

               input:first-child {
                  margin-bottom: 1.39vw;
               }

               input::placeholder {
                  @include text(#B1B1B1, 500, 1.1vw);
               }

               input.pass {
                  margin: 0
               }


               img {
                  @include size(1.6vw, .764vw);
                  position: absolute;
                  right: 2.08vw;
                  top: 6.75vw;
                  cursor: pointer;
               }

               input[type="submit"] {
                  @include size(100%, 3.82vw);
                  border-radius: 2.43vw;
                  @include flex(center, center);
                  background: linear-gradient(93.25deg, #00E0FF 0.98%, #FF00B8 104.55%);
                  cursor: pointer;
                  margin-top: 2.8vw;
                  border: none;
                  padding-left: 0vw;
               }
            }
         }
      }

      .modal.active {
         transform: scale(1);
      }

      .modal.error {
         transform: scale(1);
         height: 36.04vw;
      }
   }
}

.overflow.active {
   opacity: 1;
   pointer-events: all;
}