@media (max-width: 480px) {
   .overflow {
      @include size(100vw, 100vh);
      padding-top: 6.5625vw;

      .overflow_content {

         h2 {
            font-size: 9.375vw;
            margin-bottom: 6.25vw;
         }


         .overflow_content_reg {
            width: 93.75vw;
            border-radius: 3.125vw;
            margin-top: 3.125vw;
            font-size: 4.375vw;
            flex-direction: column;
            @include flex(center, center);

            span {
               display: block;
            }
         }

         .overflow_content_reg.active {
            height: 18.75vw;
            width: 93.75vw;
            @include flex(center, center);
         }

         .overflow_content_privat {
            font-size: 4.375vw;
            margin-top: 3.125vw;
            padding: 0 15vw;
         }

         .modal {
            position: relative;
            @include size(93.75vw, 109.4375vw);
            border-radius: 3.125vw;

            .close_modal {
               display: block;
               position: absolute;
               top: 3vw;
               right: 3vw;
               @include size(4vw, 4vw)
            }

            .modal_container {
               padding: 0 6.125vw;

               .modal_container_message {
                  margin-top: 29.06vw;

                  .modal_container_message_message {
                     @include size(91.67%, 17.1875vw);
                     border-radius: 11vw;
                     border: .625vw solid #93EF44;
                     text-align: center
                  }

                  .modal_container_message_message.en {
                     font-size: 5vw;
                  }
               }

               .modal_container_text {
                  @include text($c3, 500, 11.5vw);
                  margin: 6.25vw 0 4.25vw 0;
               }

               .modal_container_text.en {
                  font-size: 8vw;
               }

               p {
                  @include text($c3, 400, 4.375vw);
                  margin-bottom: 6.25vw;
               }

               .flex {

                  .modal_container_password {
                     font-size: 5vw;
                     margin: 10.5vw;
                  }

                  .modal_container_password.get {
                     margin-top: 29.06vw;
                  }
               }

               .error {
                  @include size(81.25vw, 14.1875vw);
                  border-radius: 11vw;
                  border: .625vw solid #F30000;
                  @include text(#F30000, 400, 4vw);
                  margin-top: 6.25vw;
               }

               form {

                  input {
                     @include size(70.6vw, 14.1875vw);
                     border-radius: 11vw;
                     border: .625vw solid $c2;
                     padding-left: 9.375vw;
                     @include text($c3, 600, 5vw);
                  }

                  input:first-child {
                     margin-bottom: 3.125vw;
                  }

                  input::placeholder {
                     @include text(#B1B1B1, 500, 5vw);
                  }


                  img {
                     @include size(7.2vw, 3.438vw);
                     right: 6.875vw;
                     top: 25.375vw;
                     cursor: pointer;
                  }

                  input[type="submit"] {
                     @include size(81.25vw, 14.1875vw);
                     border-radius: 11vw;
                     background: linear-gradient(93.25deg, #00E0FF 0.98%, #FF00B8 104.55%);
                     margin-top: 9.375vw;
                  }
               }
            }
         }

         .modal.error {
            height: 129.75vw;
         }
      }
   }
}