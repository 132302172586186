header {
   margin: 2.1vw 0 4.24vw 0;

   .header_content {
      @include flex(space-between, center);

      a {
         text-decoration: none;
         color: $c1;

         .header_content_come_main {
            @include text($c1, 400, 1.5vw);
            cursor: pointer;
         }
      }

      h1 {
         @include text($c1, 400, 3vw);
         font-family: 'Distant Galaxy';
         font-style: normal;
      }

      nav {
         width: 52.87vw;

         ul {
            width: 100%;
            list-style: none;
            @include flex(space-between, center);

            li {
               @extend %text400;
               cursor: pointer;
               transition: color .2s ease-in;

               a {
                  text-decoration: none;
                  color: $c1;
               }
            }

            li:last-child {
               margin: 0;
            }

            li:hover {
               color: $c2;
            }
         }
      }

      .header_content_flex {
         @include flex(flex-start, center);

         .header_content_sing_and_lod {
            @include bcsize(13.8vw, 3.8vw, none);
            border: .14vw solid $c2;
            border-radius: 2.43vw;
            transition: all .2s ease-in;
            @include flex(center, center);
            @extend %text500;
            cursor: pointer;
            margin: 0 2.64vw;
         }

         .header_content_sing_and_lod:hover {
            color: $c3;
            background-color: $c2;
         }

         .header_content_sing_and_lod:active {
            background-color: $c5;
            border-color: $c5;
         }

         a {
            @include size(1.25vw, 1.25vw);
            margin-right: 2.74vw;

            .header_content_massenge {
               @include size(1.25vw, 1.25vw);
               cursor: pointer;
               @include flex(center, center);

               img {
                  @include size(1.25vw, 1.1vw);
               }
            }
         }

         .header_content_language {
            @include flex(flex-start, center);

            .language {
               @include bcsize(2.1vw, 2.1vw, none);
               border-radius: 50%;
               @include flex(center, center);
               @extend %text400;
               margin-right: 1.32vw;
               cursor: pointer;
               transition: all .2s ease-in;
               padding: .35vw;
            }

            .language.active {
               color: $c3;
               background-color: $c2;
            }

            .language:last-child {
               margin: 0;
            }
         }
      }
   }
}

header.header {
   background: $c6;
   @include size(100%, 6vw);
   margin: 0;
   @include flex(center, center);

   .header_content.password {
      @include flex(space-between, center);
      width: 82%;
   }
}