@import './mixins.scss', './variables.scss';

%text400 {
   @include text($c1, 400, 1.1vw)
}

%text500 {
   @include text($c1, 500, 1.1vw)
}

%textdark {
   @include text($c3, 500, 1.1vw)
}

%text4050 {
   @include text($c1, 500, 2.78vw)
}