.about_progect {
   margin-top: 1vw;
   margin-bottom: 4.86vw;

   .about_progect_content {
      @include flex(space-between, center);

      .about_progect_content_text {

         h1 {
            @extend %text4050;
            margin-bottom: 1.74vw;
         }

         ul {
            list-style: none;

            li {
               @include text($c1, 300, 1.39vw);
               margin-bottom: 1.3vw;
            }

            li:last-child {
               margin: 0
            }
         }

         a {
            display: block;
            text-decoration: none;
            border-radius: 2.43vw;
            @include size(18.05vw, 3.82vw);
            margin: 3vw 0;

            .about_progect_content_text_button {
               @include size(18.05vw, 3.82vw);
               border-radius: 2.43vw;
               @include flex(center, center);
               cursor: pointer;
               border: .14vw solid $c2;
               transition: all .2s ease-in;
               @extend %text500;
            }
         }

         .about_progect_content_text_button:hover {
            color: $c3;
            background-color: $c2;
         }

         .about_progect_content_text_button:active {
            background-color: $c5;
            border-color: $c5;
         }

         .about_progect_content_logo {
            @include flex(flex-start, center);

            .about_progect_content_logo_text {
               @include text($c1, 300, 1.39vw);
               margin-right: 0.97vw;
            }

            img {
               @include size(13.1vw, 3.2vw);
            }
         }
      }

      .about_progect_content_image {
         margin-top: 6vw;

         img.mobile {
            display: none;
         }

         img {
            @include size(49vw, 68.6vw);
         }
      }
   }
}