.page_password {
   .modal {
      @include size(100%, 30vw);
      @include flex(center, center);

      .modal_container {
         width: 50%;

         .modal_container_text {
            @include text($c3, 500, 2.8vw);
            text-align: center;
            margin: 2.8vw 0 1.8vw 0;
         }

         .error {
            @include size(104%, 3.5vw);
            border-radius: 2.43vw;
            border: .139vw solid #F30000;
            @include text(#F30000, 400, 1.1vw);
            @include flex(center, center);
            text-align: center;
            margin-top: 1.39vw
         }

         .get_true {
            @include size(104%, 3.5vw);
            border-radius: 2.43vw;
            border: .139vw solid #03b409;
            @include text(#03b409, 400, 1.1vw);
            @include flex(center, center);
            text-align: center;
            margin-top: 1.39vw
         }

         form {
            position: relative;

            input {
               @include size(100%, 3.5vw);
               border-radius: 2.43vw;
               border: .139vw solid $c2;
               outline: none;
               padding-left: 2.08vw;
               @include text($c3, 600, 1.1vw);
            }

            input:first-child {
               margin-bottom: 1.39vw;
            }

            input::placeholder {
               @include text(#B1B1B1, 500, 1.1vw);
            }

            input.pass {
               margin: 0
            }

            input[type="submit"] {
               @include size(105%, 3.82vw);
               border-radius: 2.43vw;
               @include flex(center, center);
               background: linear-gradient(93.25deg, #00E0FF 0.98%, #FF00B8 104.55%);
               cursor: pointer;
               margin-top: 2.8vw;
               border: none;
               padding-left: 0vw;
            }
         }
      }
   }
}