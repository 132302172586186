.main_screen {

   .main_screen_content {
      @include flex(space-between, center);

      .main_screen_content_text {

         h1 {
            @include text($c1, 400, 3.47vw);
            margin-bottom: 1.4vw;
            font-family: 'Distant Galaxy';
            font-style: normal;
         }

         h2 {
            @include text($c1, 500, 3.125vw);
            margin-bottom: 2.2vw;

            span {
               background-image: linear-gradient(to right, $c2, $c4 160%);
               color: transparent;
               background-clip: text;
            }
         }

         .main_screen_content_text_buttons {
            @include flex(flex-start, center);

            a {
               text-decoration: none;
            }

            .buttons {
               @include size(15.28vw, 3.82vw);
               border-radius: 2.43vw;
               @include flex(center, center);
               cursor: pointer;
               z-index: 100;
               transition: all .2s ease-in;
            }

            .main_screen_content_text_buttons_invest {
               background: linear-gradient(93.25deg, $c2 0.98%, $c4 104.55%);
               @extend %textdark;
               margin-right: 1.4vw;
            }

            .main_screen_content_text_buttons_go_website {
               @extend %text500;
               position: relative;

               img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  @include size(15.28vw, 3.82vw);
                  border-radius: 2.43vw;
               }
            }

            .main_screen_content_text_buttons_go_website:hover {
               color: $c3;
            }

            .main_screen_content_text_buttons_go_website:before {
               border-radius: 2.43vw;
               background-image: linear-gradient(93.25deg, $c2 0.98%, $c4 104.55%);
               content: '';
               display: block;
               height: 100%;
               position: absolute;
               top: 0;
               left: 0;
               opacity: 0;
               width: 100%;
               z-index: -100;
               transition: opacity .2s;
            }

            .main_screen_content_text_buttons_go_website:hover:before {
               opacity: 1;
            }
         }
      }

      .main_screen_content_image {
         position: relative;

         img {
            @include size(44vw, 35vw);
         }

         .main_screen_content_image_video {
            position: absolute;
            top: 43%;
            left: 43%;

            .main_screen_content_image_video_button {
               position: relative;
               @include bcsize(6vw, 4vw, #FF0000);
               cursor: pointer;
               z-index: 10;
               overflow: hidden;
               border-radius: 1vw;
               @include flex(center, center);

               img {
                  @include size(2vw, 1.73vw);
                  margin-left: .3vw;
               }
            }

            .main_screen_content_image_video_button::before {
               position: absolute;
               content: '';
               top: 0;
               left: 0;
               width: 2vw;
               height: 100%;
               z-index: 11;
               background: rgba(255, 255, 255, 0.6);
               transform: translateX(-3vw) skew(-40deg);
               animation: move 5s 1s infinite;
            }

            @keyframes move {
               0% {
                  transform: translateX(-6vw) skew(-40deg)
               }

               100% {
                  transform: translateX(18vw) skew(-40deg)
               }
            }
         }
      }
   }
}