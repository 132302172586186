@media (max-width: 480px) {
   .navbar {
      position: absolute;
      top: 0;
      left: 150%;
      @include bcsize(100%, 12%, $c2);
      z-index: 1000;
      transition: left .3s ease-in;

      .navbar_container {
         position: relative;
         padding: 11vw 0 0 9.375vw;

         .navbar_container_leng {
            @include flex(flex-start, center);
            margin-bottom: 7.8125vw;

            .navbar_container_leng_block {
               @include size(9.375vw, 9.375vw);
               border-radius: 50%;
               @include flex(center, center);
               @include text($c1, 400, 5vw)
            }

            .navbar_container_leng_block:first-child {
               margin-right: 3.125vw;
            }

            .navbar_container_leng_block.active {
               background-color: $c3;
            }
         }

         nav {
            margin-bottom: 6.5vw;

            ul {
               list-style: none;

               li {
                  @include text($c3, 400, 5vw);
                  margin-bottom: 2.8125vw;

                  a {
                     text-decoration: none;
                     color: $c3;
                  }
               }
            }
         }

         .navbar_container_message {
            @include flex(flex-start, center);

            a {
               margin-right: 3.125vw;

               .message {
                  margin: 0
               }
            }

            a:last-child {
               margin: 0
            }

            .message {
               @include bcsize(9.375vw, 9.375vw, $c3);
               border-radius: 50%;
               @include flex(center, center);
               margin-right: 3.125vw;

               img:nth-child(1) {
                  @include size(5vw, 4.35vw);
               }

               img:nth-child(2) {
                  @include size(5.625vw, 4.35vw);
               }

               img:nth-child(3) {
                  @include size(5.625vw, 5.625vw);
               }
            }
         }

         img.close {
            position: absolute;
            top: 5vw;
            right: 5vw;
         }
      }
   }
}