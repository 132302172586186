.resources_and_partners {
   position: absolute;
   bottom: 37vw;
   left: 0;
   @include bcsize(100%, 6.67vw, linear-gradient(93.25deg, #00E0FF 0.98%, #FF00B8 104.55%));

   .resources_and_partners_container {
      @include flex(space-between, center);
      @include size(81vw, 100%);
      margin: 0 auto;

      .resources_and_partners_container_left_block {

         h4 {
            @extend %text400;
            color: #0B0A3E;
         }
      }

      .resources_and_partners_container_right_block {
         width: 67vw;

         ul {
            padding: 0;
            margin: 0;
            list-style: none;
            @include flex(flex-end, center);

            li {
               a {
                  display: inline-block;

                  img {
                     @include size(100%, 100%);
                  }
               }
            }
         }

         .resources_and_partners_container_right_block_top {
            @include size(100%, 2.1vw);

            ul {
               @include size(100%, 100%);
               @include flex(space-between, center);

               li:nth-child(1) {
                  a {
                     @include size(6.81vw, 2.1vw);
                  }
               }

               li:nth-child(2) {
                  a {
                     @include size(2.1vw, 2.1vw);
                  }
               }

               li:nth-child(3) {
                  a {
                     @include size(8vw, 2.1vw);
                  }
               }


               li:nth-child(4) {
                  a {
                     @include size(9vw, 2.1vw);
                  }
               }

               li:nth-child(5) {
                  a {
                     @include size(5.24vw, 2.1vw);
                  }
               }

               li:nth-child(6) {
                  a {
                     @include size(8vw, 2.1vw);
                  }
               }

               li:nth-child(7) {

                  a {
                     @include size(5vw, 2.1vw);
                  }
               }

               li:nth-child(8) {
                  a {
                     @include size(8vw, 2.1vw);
                  }
               }
            }
         }

         .resources_and_partners_container_right_block_bottom {
            @include size(100%, 2.1vw);
            margin-top: .7vw;

            ul {
               @include size(100%, 100%);
               @include flex(space-between, center);

               li:nth-child(1) {
                  a {
                     @include size(12vw, 1.4vw);
                  }
               }

               li:nth-child(2) {
                  a {
                     @include size(4.5vw, 1.4vw);
                  }
               }

               li:nth-child(3) {
                  a {
                     @include size(6.54vw, 2.1vw);
                  }
               }

               li:nth-child(4) {
                  a {
                     @include size(8.57vw, 2.1vw);
                  }
               }

               li:nth-child(5) {
                  a {
                     @include size(6.5vw, 2.1vw);
                  }
               }

               li:nth-child(6) {
                  a {
                     @include size(10vw, 1.76vw);
                  }
               }

               li:nth-child(7) {
                  a {
                     @include size(8vw, 1.76vw);
                  }
               }
            }
         }
      }
   }
}