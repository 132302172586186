@media (max-width: 480px) {
   .about_progect {
      margin: 25vw 0;
      padding-top: 13.75vw;

      .about_progect_content {
         position: relative;
         z-index: 100;
         display: block;

         .about_progect_content_text {

            h1 {
               position: absolute;
               top: -13.75vw;
               @include text($c1, 500, 7.8125vw);
               margin-bottom: 6.25vw;
               z-index: 10;
               display: block;
               width: 100%;
               text-align: center;
            }

            ul {
               list-style: none;
               text-align: center;

               li {
                  @include text($c1, 300, 4.375vw);
                  margin-bottom: 4.5vw;
               }

               li:last-child {
                  margin-bottom: 198.125vw;
               }
            }

            a {
               @include size(75vw, 17.1875vw);
               border-radius: 11vw;
               margin: 0 auto;

               .about_progect_content_text_button {
                  @include size(75vw, 17.1875vw);
                  border-radius: 11vw;
                  @include flex(center, center);
                  cursor: pointer;
                  border: .625vw solid $c2;
                  transition: all .2s ease-in;
                  @include text($c1, 500, 4.375vw);
               }
            }

            .about_progect_content_text_button:hover {
               color: $c3;
               background-color: $c2;
            }

            .about_progect_content_text_button:active {
               background-color: $c5;
               border-color: $c5;
            }

            .about_progect_content_logo {
               @include flex(center, center);
               margin-top: 6.25vw;

               .about_progect_content_logo_text {
                  @include text($c1, 300, 4.375vw);
               }

               img {
                  @include size(31.875vw, 7.8125vw);
               }
            }
         }

         .about_progect_content_image {
            margin-top: 6vw;

            img {
               display: none;
            }

            img.mobile {
               display: block;
            }

            img.mobile.luna {
               @include size(28.92vw, 28.92vw);
               position: absolute;
               right: 10.77vw;
               top: -28.125vw;
               z-index: 1;
            }

            img.mobile.rocket {
               position: absolute;
               right: 0vw;
               bottom: 30vw;
               @include size(93.75vw, 187.8125vw)
            }
         }
      }
   }
}