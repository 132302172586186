.strategy {
   margin-bottom: 8.88vw;
   position: relative;
   z-index: 10;

   .fon {
      @include size(54vw, 49.1vw);
      position: absolute;
      top: -10vw;
      right: -6vw;
      z-index: 1;
   }

   h1 {
      @extend %text4050;
      margin-bottom: 3.47vw;
   }

   .strategy_content {
      @include flex(space-between, flex-start);

      ul {
         list-style: none;

         li {
            @extend %text400;
         }
      }

      .strategy_content_left {

         h2 {
            @include text($c2, 700, 2.08vw);
            display: inline-block;
            margin-bottom: 1.8vw;
         }

         .strategy_content_left_flex {
            position: relative;

            img {
               height: 33.33vw;
            }

            ul {
               position: absolute;
               left: 6.945vw;
               top: 0;
               width: 12.5vw;

               li {
                  line-height: 1.32vw;
                  height: 3.9683vw;
                  margin-bottom: 2.43vw;
               }
            }
         }
      }

      .strategy_content_right {

         h2 {
            display: inline-block;
            margin-bottom: 1.528vw;
            @include text($c4, 700, 2.08vw);
         }

         .strategy_content_right_flex {
            position: relative;
            z-index: 10;

            img {
               width: 52.78vw;
            }

            img.etaps {
               display: none;
            }

            .strategy_content_right_flex_block {
               ul {
                  margin-top: .33vw;
                  @include flex(flex-start, flex-start);

                  li {
                     @include size(16.25vw, 12.639vw);
                     line-height: 1.8vw;
                     margin-right: 0vw;
                  }

                  li:nth-child(1) {
                     margin-right: 1.806vw;
                  }

                  li:nth-child(2) {
                     margin-right: 2.22vw;
                  }
               }

               ul.top {
                  position: absolute;
                  top: 5.63vw;
                  right: 0;
               }
            }
         }
      }
   }
}