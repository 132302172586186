@media (max-width: 480px) {
   .team {
      height: 155vw;

      h1 {
         @include text($c1, 500, 7.8125vw);
         text-align: center;
         margin-bottom: 6.25vw;
      }

      .team_content {
         position: relative;

         .team_scroll {
            display: block;
            @include size(3.75vw, 7.1875vw);

            img:first-child {
               position: absolute;
               left: 2vw;
               top: 42vw;
               z-index: 100;
            }

            img:last-child {
               position: absolute;
               right: 2vw;
               top: 42vw;
               z-index: 100;
            }
         }

         .team_flex {
            position: absolute;
            top: 0;
            left: 9.375vw;
            z-index: 1;
            @include flex(flex-start, flex-start);
            transition: left .3s ease-in;

            .team_content_top {
               @include flex(flex-start, flex-start);
            }

            .team_content_bottom {
               @include flex(flex-start, flex-start);
               flex-wrap: nowrap;
            }

            .team_content_top,
            .team_content_bottom {

               .card {
                  margin-bottom: 25vw;
                  border-radius: 3.125vw;
                  padding: 5vw 14vw;
                  position: relative;
                  @include size(47vw, 84vw);
                  margin-right: 22vw;

                  .card_image {

                     img {
                        @include size(26.25vw, 26.25vw);
                     }

                     .card_image_text {
                        position: absolute;
                        padding: .7vw 1.4vw;
                        left: 35%;
                        bottom: -1.8vw;
                        border-radius: 1.6vw;
                        font-size: 3vw;
                     }
                  }

                  .card_name {
                     margin: 3.125vw 0 1.5625vw 0;
                     @include text($c2, 500, 5vw);
                  }

                  p {
                     @include text($c1, 400, 3.125vw);
                     line-height: 3.75vw;
                  }

                  .card_message {

                     .card_message_link,
                     .card_message_face {

                        a {
                           @include size(6vw, 6vw);
                        }
                     }

                     .card_message_link {
                        margin-right: 3vw;
                     }

                     .card_message_face.last {
                        @include size(6vw, 6vw);
                     }
                  }

                  .card_message.one {
                     left: 47%;
                  }
               }
            }
         }
      }
   }
}