@media (max-width: 480px) {
   .statistics {
      margin-bottom: 25vw;

      .statistics_content {

         h1 {
            @include text($c1, 500, 7.8125vw);
            text-align: center;
            margin-bottom: 6.25vw;
         }

         .statistics_content_data {
            flex-direction: column;
            @include flex(center, center);

            .statistics_content_data_booking {
               @include bcsize(93.75vw, 20vw, rgba(0, 209, 255, 0.1));
               @include text($c1, 700, 4.375vw);
               border-radius: 3.125vw;
               @include flex(center, center);
               text-align: center;

               span {
                  @include text($c2, 700, 6.25vw);
               }
            }

            .statistics_content_data_data {
               display: none;
            }

            .statistics_content_data_data_mobile {
               @include flex(space-between, flex-start);
               width: 100%;

               .statistics_content_data_data_top_block_mobile,
               .statistics_content_data_data_bottom_block_mobile {
                  width: 45.3125vw;

                  ul {
                     list-style: none;
                     flex-direction: column;
                     @include flex(center, flex-start);
                     margin-top: 3.125vw;

                     li {
                        @include text($c1, 400, 4.375vw);
                        margin-bottom: 3.125vw;
                        padding: 3.125vw;
                        width: 100%;
                        @include bcsize(39.0625vw, 20.3125vw, rgba(0, 209, 255, 0.1));
                        border-radius: 3.125vw;

                        span {
                           @include text($c2, 400, 6.25vw);
                           line-height: 8vw;
                           margin-bottom: 0;
                        }

                        .li_modal {
                           @include flex(flex-start, center);

                           span {
                              transform: none;
                              line-height: 5vw;
                           }

                           span.li_modal_text {
                              margin-bottom: 0;
                              margin-left: 0;
                              color: $c1;
                              @include text($c1, 400, 4.375vw);
                           }

                           span.li_modal_modal {
                              margin-left: 3.17vw;
                              @include size(8vw, 8vw);
                              border-radius: 50%;
                              border: .635vw solid $c2;
                              @include flex(center, center);
                              cursor: pointer;

                              span.li_modal_modal_arrow {
                                 margin-bottom: 1vw;
                              }
                           }
                        }
                     }
                  }
               }

               .statistics_content_data_data_bottom_block {
                  ul {
                     li {
                        margin-top: 0;
                     }
                  }
               }
            }
         }

         .statistics_content_prise {
            flex-direction: column;
            @include flex(center, center);
            margin-top: 5.25vw;

            .statistics_content_prise_text {
               @include text($c1, 500, 5vw);

               span {
                  font-size: 6vw;
                  margin-left: 0;
                  padding: 0 3.17vw;
               }

               span.questionRu::before,
               span.questionEn::before {
                  bottom: 8vw;
                  left: -59vw;
                  @include text($c1, 400, 3.17vw);
                  line-height: 3.81vw;
                  width: 80vw;
                  border-radius: 3.17vw;
                  padding: 3.17vw 3.81vw;
               }

               span.questionRu::before {
                  height: 45vw;
                  content: 'Прогнозируемая дисконтированная цена токена\A рассчитывается следующим образом:\A 1. Учитывая общую выручку проекта, долю прибыли\A и долю на покупку токенов, мы расчитываем\A среднедневной прирост суммы на покупку токена.\A 2. Суммируя эту сумму по дням на протяжении\A дисконтируемого периода, мы получаем сумму на\A покупку токенов в будущем, при условии\A сохранения текущей динамики выручки.\A 3. Делим эту сумму на общее количество токенов в\A обращении, чтобы получить прогнозируемую цену\A токена.';
               }

               span.questionEn::before {
                  height: 45vw;
                  content: 'The predicted discounted price of the token is\A calculated as follows:\A 1. Taking into account the total revenue of the project,\A the share of profit and the share for the purchase of\A tokens, we calculate the average daily increase\A in the amount for the purchase of a token.\A 2. Summing up this amount by days during the\A discounted period, we receive the amount for the\A purchase of tokens in the future, provided that the\A current revenue dynamics is maintained.\A 3. Divide this amount by the total number of tokens in\A circulation to get the predicted price of the token.';
               }
            }

            .statistics_content_prise_line {
               display: none;
            }

            .statistics_content_prise_prise {
               margin-top: 3.125vw;
               @include bcsize(75vw, 8.75vw, $c2);
               border-radius: 11vw;
               @include flex(center, center);
               @include text($c3, 700, 5vw);
               cursor: pointer;
            }
         }
      }
   }
}