.team {

   h1 {
      @extend %text4050;
      margin-bottom: 2.85vw;
   }

   .team_content {

      .team_scroll {
         display: none;
      }

      .team_content_top {
         @include flex(space-between, flex-start);
      }

      .team_content_bottom {
         @include flex(space-between, flex-start);
         flex-wrap: wrap;
      }

      .team_content_top,
      .team_content_bottom {

         .card {
            background-color: rgba(0, 209, 255, .1);
            margin-bottom: 2.78vw;
            border-radius: .7vw;
            padding: 2.43vw 2.08vw;
            position: relative;

            .card_image {
               position: relative;
               @include flex(center, center);

               img {
                  @include size(11.4vw, 11.4vw);
                  border-radius: 50%;
               }

               .card_image_text {
                  position: absolute;
                  padding: .21vw .35vw;
                  left: 38%;
                  bottom: -.35vw;
                  background: #677C9B;
                  border-radius: .35vw;
                  @include text($c1, 500, 1.1vw);
               }
            }

            .card_name {
               margin: 1.1vw 0;
               text-align: center;
               @include text($c2, 500, 1.39vw);
            }

            p {
               @extend %text400;
               text-align: center;
               line-height: 1.32vw;
            }

            .card_message {
               display: flex;
               position: absolute;
               bottom: 3vw;
               left: 40%;

               .card_message_link,
               .card_message_face {

                  a {
                     @include size(2.08vw, 2.08vw);
                     display: inline-block;
                     cursor: pointer;
                     border-radius: 50%;

                     img {
                        @include size(100%, 100%);
                        border-radius: 50%;
                     }
                  }
               }

               .card_message_link {
                  margin-right: 1vw;
               }
            }

            .card_message.one {
               left: 46%;
            }
         }
      }

      .team_content_top .card {
         @include size(20.84vw, 31.04vw);
      }

      .team_content_bottom .card {
         @include size(20.84vw, 22.46vw);
      }

      .team_content_bottom .card.last {
         height: 31.04vw;
      }
   }
}